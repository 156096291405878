import { setLoading } from "appdata/loader/loaderSlice";
import SearchBar from "components/SearchBar/SearchBar";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";

import AvatarGroupImg from "assets/img/avatar-group.webp";

import {
  GetPatientsParams,
  getPatients,
  getPatientsOfDoctor,
  updatePatientsOfDoctor,
} from "appdata/patients/patientsSlice";
import { AppDispatch, RootState } from "appdata/store";
import { useNotification } from "contexts/notification-context/NotificationContext";
import debounce from "debounce";
import { useNavigate } from "react-router-dom";
import { arraysEqual } from "utils/func";
import styles from "./AddPatientPopup.module.scss";

interface AddPatientPopupProps {
  openCreateModal: boolean;
  setOpenCreateModal: (isOpen: boolean) => void;
}

function AddPatientPopup({
  openCreateModal,
  setOpenCreateModal,
}: AddPatientPopupProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { notifyError } = useNotification();
  const { t } = useTranslation();

  const tableScrollRef = useRef<HTMLDivElement | null>(null);

  const patientsRedux = useSelector((state: RootState) => state.patientsRedux);
  const [valueSearch, setValueSearch] = useState<string>("");
  const [initialSelectedPatients, setInitialSelectedPatients] = useState<
    string[]
  >([]);
  const [selectedPatients, setSelectedPatients] = useState<string[]>([]);
  const [isFetching, setIsFetching] = useState<{
    isFetching: boolean;
    useInfinityScroll: boolean;
  }>({
    isFetching: false,
    useInfinityScroll: false,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    // setIsFetching({ isFetching: true, useInfinityScroll: false });
    setCurrentPage(1);

    const getPatientsDebounce = debounce(() => {
      const params: GetPatientsParams = {
        page: 1,
        limit: 20,
        useInfinityScroll: false,
        name: valueSearch,
      };

      dispatch(getPatients(params));
    }, 300);

    getPatientsDebounce();
    return () => getPatientsDebounce.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueSearch]);

  const fetchMorePatients = () => {
    const nextPage = currentPage + 1;
    // Kiểm tra nếu trang tiếp theo vượt quá tổng số trang
    if (patientsRedux.metadataPatientsPage!.hasNextPage) {
      const paramsNextPage: GetPatientsParams = {
        page: nextPage,
        limit: 20,
        useInfinityScroll: isFetching.useInfinityScroll,
        name: valueSearch,
      };

      dispatch(getPatients(paramsNextPage));
      setCurrentPage(nextPage);
    }
    setIsFetching({ isFetching: false, useInfinityScroll: false });
  };
  let scrollTimeout: NodeJS.Timeout | null = null;

  const handleScroll = () => {
    if (tableScrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = tableScrollRef.current;
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
      scrollTimeout = setTimeout(() => {
        if (
          scrollTop + clientHeight + 50 >= scrollHeight &&
          !isFetching.isFetching
        ) {
          setIsFetching({ isFetching: true, useInfinityScroll: true });
        }
      }, 100);
    }
  };

  useEffect(() => {
    if (isFetching.isFetching) {
      fetchMorePatients();
    }
    const current = tableScrollRef.current;
    if (current) {
      current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (current) {
        current.removeEventListener("scroll", handleScroll);
        if (scrollTimeout) {
          clearTimeout(scrollTimeout); // Dọn dẹp timeout khi component unmount
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const handleSelectPatient = (patientId: string) => {
    setSelectedPatients((prevSelected) =>
      prevSelected.includes(patientId)
        ? prevSelected.filter((id) => id !== patientId)
        : [...prevSelected, patientId]
    );
  };

  const handleSave = () => {
    if (arraysEqual(selectedPatients, initialSelectedPatients)) {
      setOpenCreateModal(false);
      return;
    }

    dispatch(setLoading(true));
    dispatch(updatePatientsOfDoctor({ patients: selectedPatients }))
      .unwrap()
      .then(() => {
        dispatch(getPatientsOfDoctor());
        dispatch(setLoading(false));
        setOpenCreateModal(false);
        navigate("/dashboard");
      })
      .catch((error) => {
        notifyError();
      });
  };

  useEffect(() => {
    if (!patientsRedux.patientsOfDoctor) return;

    // Lọc ra chỉ những patient.id không phải là undefined
    const selectedPatientIds = patientsRedux.patientsOfDoctor
      .map((patient) => patient.id)
      .filter((id): id is string => id !== undefined); // Type guard để đảm bảo id là string

    setSelectedPatients(selectedPatientIds);
    setInitialSelectedPatients(selectedPatientIds);
  }, [patientsRedux.patientsOfDoctor]);

  return (
    <Popup
      contentStyle={{ zIndex: 1001 }}
      open={openCreateModal}
      modal
      onClose={() => {
        setOpenCreateModal(false);
      }}
      closeOnDocumentClick={false}
    >
      <div className={styles.addPatientPopup}>
        <div className={styles.header_popup}>
          <img alt="avt" src={AvatarGroupImg} />
          <span className="size_large text_gradient">
            {t("button.addPatientToTheWidget")}
          </span>
          <p>{`"${t("create_dashboard_page.addNewPatientsMessage")}"`}</p>
        </div>

        <div
          style={{
            padding: "0 6.25vw",
            margin: "1.25vw 0",
          }}
        >
          <SearchBar
            valueSearch={valueSearch}
            setValueSearch={setValueSearch}
            containerStyle={{
              width: "100%",
              height: "3.125vw",
              borderRadius: "1000px",
              border: "1px solid #EFF0F6",
            }}
          ></SearchBar>
        </div>

        <div style={{ overflow: "hidden", flex: "1", marginBottom: "2vw" }}>
          <div
            ref={tableScrollRef}
            className={styles.table_scroll}
            onScroll={handleScroll}
          >
            <table>
              <thead>
                <tr>
                  <th className={styles.column4}>
                    {t("common_text.patientId")}
                  </th>
                  <th className={styles.column4}>
                    {t("common_text.fullName")}
                  </th>
                  <th className={styles.column2}>{t("table.select")}</th>
                </tr>
              </thead>
              <tbody>
                {patientsRedux.patients?.map((patient) => (
                  <tr key={patient.id}>
                    <td>{patient.username ?? ""}</td>
                    <td style={{ color: "#127ec5" }}>{patient.name ?? ""}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedPatients.includes(patient.id!)}
                        onChange={() => handleSelectPatient(patient.id!)}
                        className={styles.custom_checkbox}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.footer_popup}>
          <button
            className="button_secondary rounded"
            onClick={() => setOpenCreateModal(false)}
          >
            {t("button.cancel")}
          </button>
          <button
            className="button_primary rounded"
            onClick={handleSave}
            disabled={selectedPatients.length === 0}
          >
            {t("button.save")}
          </button>
        </div>
      </div>
    </Popup>
  );
}

AddPatientPopup.propTypes = {
  openCreateModal: PropTypes.bool,
  setOpenCreateModal: PropTypes.func,
};

export default AddPatientPopup;
