import { usePatient } from "contexts/patient-context/PatientContext";
import { useTranslation } from "react-i18next";

import GameDetailLayout from "../GameDetailLayout";
import { StyledItem } from "components/TableSticky/TableSticky";
import {
  NeckThrowDataTableRow,
  NeckThrowSessionData,
} from "types/games/neckThrowGameTypes";

function DetailNeckThrow() {
  const { t } = useTranslation();
  const { setSessionDetailSelected, results } = usePatient();

  const headTabs = [
    { key: "order", label: t("table.order") },
    { key: "spentTime", label: t("table.spentTime") },
    { key: "headUp", label: t("game.headUpCount") },
    { key: "headDown", label: t("game.headDownCount") },
    { key: "view", label: t("table.viewDetails") },
  ];

  const columnStyles: StyledItem[] = [
    {
      key: "view",
      style: {
        textDecoration: "underline", // Thay đổi từ textDecorationLine thành textDecoration
        color: "var(--text-color-blue)",
        cursor: "pointer",
      },
    },
  ];

  const convertDataToDataTable = (
    _data: NeckThrowSessionData[]
  ): NeckThrowDataTableRow[] | null => {
    if (_data.length === 0) return null;

    return _data.map((session, index) => {
      return {
        order: index + 1,
        spentTime: session.spentTime.toFixed(2),
        headUp: session.lookDatas.filter((el) => el.name === "HeadUp").length,
        headDown: session.lookDatas.filter((el) => el.name === "HeadDown")
          .length,
        view: (
          <div
            onClick={() => {
              setSessionDetailSelected(session); // Bạn cần đảm bảo rằng setSessionDetailSelected được định nghĩa
            }}
          >
            {t("table.viewDetails")} {/* Đảm bảo rằng t là một hàm để dịch */}
          </div>
        ),
      };
    });
  };

  const totalSpentTime = results.totalTime.toFixed(0);

  return (
    <GameDetailLayout
      totalSpentTime={totalSpentTime}
      headTabs={headTabs}
      columnStyles={columnStyles}
      data={results.result}
      dataConverter={convertDataToDataTable}
    />
  );
}

export default DetailNeckThrow;
