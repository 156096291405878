import { usePatient } from "contexts/patient-context/PatientContext";
import { useTranslation } from "react-i18next";

import {
  fruitSorterDataTableRow,
  fruitSorterSessionData,
} from "types/games/fruitSorterGameTypes";
import GameDetailLayout from "../GameDetailLayout";

function DetailFruitSorter() {
  const { t } = useTranslation();
  const { results } = usePatient();

  const headTabs = [
    { key: "order", label: t("table.order") },
    { key: "spentTime", label: t("table.spentTime") },
    { key: "numberOfTypeFruit", label: t("game.numberOfTypeFruit") },
    { key: "numberOfSortedFruit", label: t("game.numberOfSortedFruit") },
    { key: "incorrects", label: t("game.incorrects") },
  ];

  const convertDataToDataTable = (
    _data: fruitSorterSessionData[]
  ): fruitSorterDataTableRow[] | null => {
    if (_data.length === 0) return null;

    return _data.map((session, index) => {
      return {
        order: index + 1,
        spentTime: session.spentTime.toFixed(2),
        numberOfTypeFruit: session.numberOfTypeFruit,
        numberOfSortedFruit: `${session.countOfSortedFruit} / ${session.numberOfFruit}`,
        incorrects: session.countOfIncorrect,
      };
    });
  };

  const totalSpentTime = results.totalTime.toFixed(0);

  return (
    <GameDetailLayout
      totalSpentTime={totalSpentTime}
      headTabs={headTabs}
      data={results.result}
      dataConverter={convertDataToDataTable}
    />
  );
}

export default DetailFruitSorter;
