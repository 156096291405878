import { App } from "antd";
import React, { createContext, ReactNode, useContext, useMemo } from "react";

type NotificationType = "success" | "error" | "info" | "warning";

interface NotificationContextProps {
  openNotification: (
    type: NotificationType,
    message: string,
    description?: string
  ) => void;
  notifyError: () => void;
  notifySuccess: () => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(
  undefined
);

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const { notification } = App.useApp();

  const openNotification = (
    type: NotificationType,
    message: string,
    description?: string
  ) => {
    notification[type]({
      message,
      description,
      duration: 3,
      showProgress: true,
      pauseOnHover: true,
    });
  };

  const notifySuccess = () => {
    openNotification("success", "Thành công!", "Bạn đã thực hiện thành công.");
  };

  const notifyError = () => {
    openNotification("error", "Có lỗi!", "Đã xảy ra lỗi, vui lòng thử lại.");
  };

  const contextValue = useMemo(
    () => ({
      openNotification,
      notifyError,
      notifySuccess,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = (): NotificationContextProps => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};
