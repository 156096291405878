import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  BaseApiParams,
  PageResponseData,
  PaginationMetadata,
} from "types/apiTypes";
import { Game } from "types/gameTypes";
import axiosInstance from "utils/api";
import { generateQueryParam } from "utils/urlEncode";

interface GamesState {
  games: Game[] | null;
  metadataGamesPage: PaginationMetadata | null;
}

const initialState: GamesState = {
  games: null,
  metadataGamesPage: null,
};

// Tạo slice cho games
export const gamesSlice = createSlice({
  name: "games",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGames.pending, (state) => {
        // Có thể xử lý trạng thái pending nếu cần
      })
      .addCase(getGames.fulfilled, (state, action) => {
        state.games = action.payload.data; // Lưu dữ liệu game
        state.metadataGamesPage = action.payload.meta; // Lưu metadata
      })
      .addCase(getGames.rejected, (state, action) => {
        // Có thể xử lý trạng thái rejected nếu cần
      });
  },
});

export interface GetGamesParams extends BaseApiParams {
  name?: string;
  useInfinityScroll?: boolean;
}
// Tạo async thunk để lấy dữ liệu games
export const getGames = createAsyncThunk<
  PageResponseData<Game>,
  GetGamesParams
>("games/getGames", async (params: Record<string, any> | undefined) => {
  try {
    const queryStringPart = generateQueryParam(params || {});
    const response = await axiosInstance.get(`/games${queryStringPart}`);
    return response.data; // Giả sử response.data đã có định dạng đúng
  } catch (error) {
    throw new Error(error instanceof Error ? error.message : "Unknown error");
  }
});

export default gamesSlice.reducer;
