import { usePatient } from "contexts/patient-context/PatientContext";
import { useTranslation } from "react-i18next";
import GameDetailLayout from "../GameDetailLayout";
import { TmtADataTableRow, TmtASessionData } from "types/games/tmtAGameTypes";

function DetailTmt() {
  const { t } = useTranslation();
  const { results } = usePatient();

  const headTabs = [
    { key: "order", label: t("table.order") },
    { key: "spentTime", label: t("table.spentTime") },
    { key: "totalPoints", label: t("game.totalPoints") },
    { key: "numberOfCorrections", label: t("game.numberOfCorrections") },
    { key: "numberOfIncorrections", label: t("game.numberOfIncorrections") },
  ];

  const convertDataToDataTable = (
    _data: TmtASessionData[]
  ): TmtADataTableRow[] | null => {
    if (_data.length === 0) return null;

    return _data.map((session, index) => {
      return {
        order: index + 1,
        spentTime: session.spentTime.toFixed(2),
        totalPoints: session.totalPoints,
        numberOfCorrections: session.numberOfCorrections,
        numberOfIncorrections: session.numberOfIncorrections,
      };
    });
  };

  const totalSpentTime = results.totalTime.toFixed(0);

  return (
    <GameDetailLayout
      totalSpentTime={totalSpentTime}
      headTabs={headTabs}
      data={results.result}
      dataConverter={convertDataToDataTable}
    />
  );
}

export default DetailTmt;
