import { LoaderProvider } from "contexts/loader-context/LoaderContext";
import { NotificationProvider } from "contexts/notification-context/NotificationContext";
import { PopupProvider } from "contexts/popup-context/PopupContext";
import "react-day-picker/style.css";
import { Provider } from "react-redux";
import AppRoutes from "routes/AppRoutes";
import packageJson from "../package.json";
import "./App.css";
import { store } from "./appdata/store";

function App() {
  console.log(
    `%c
  ▗▄▄▄▖▗▄▄▄▖▗▖   ▗▄▄▄▖    ▗▄▄▄  ▗▄▖  ▗▄▄▖▗▄▄▄▖▗▄▖ ▗▄▄▖ 
    █  ▐▌   ▐▌   ▐▌       ▐▌  █▐▌ ▐▌▐▌     █ ▐▌ ▐▌▐▌ ▐▌
    █  ▐▛▀▀▘▐▌   ▐▛▀▀▘    ▐▌  █▐▌ ▐▌▐▌     █ ▐▌ ▐▌▐▛▀▚▖
    █  ▐▙▄▄▖▐▙▄▄▖▐▙▄▄▖    ▐▙▄▄▀▝▚▄▞▘▝▚▄▄▖  █ ▝▚▄▞▘▐▌ ▐▌
`,
    "color: #e74c3c; font-size: 16px; font-weight: bold;"
  );
  console.log(
    `%cVersion: %c ${packageJson.version}!`,
    "color: #3498db; font-size: 14px;",
    "color: #008345; font-size: 14px;"
  );
  return (
    <Provider store={store}>
      <NotificationProvider>
        <LoaderProvider>
          <PopupProvider>
            <AppRoutes></AppRoutes>
          </PopupProvider>
        </LoaderProvider>
      </NotificationProvider>
    </Provider>
  );
}

export default App;
