import { usePatient } from "contexts/patient-context/PatientContext";
import { useTranslation } from "react-i18next";

import {
  JumpFrogDataTableRow,
  JumpFrogSessionData,
} from "types/games/jumpFrogGameTypes";
import GameDetailLayout from "../GameDetailLayout";

function DetailJumpFrog() {
  const { t } = useTranslation();
  const { results } = usePatient();

  const headTabs = [
    { key: "order", label: t("table.order") },
    { key: "spentTime", label: t("table.spentTime") },
    { key: "countOfSquats", label: t("game.countOfSquats") },
    { key: "countOfFalls", label: t("game.countOfFalls") },
  ];

  const convertDataToDataTable = (
    _data: JumpFrogSessionData[]
  ): JumpFrogDataTableRow[] | null => {
    if (_data.length === 0) return null;

    return _data.map((session, index) => {
      return {
        order: index + 1,
        spentTime: session.spentTime.toFixed(2),
        countOfSquats: session.countOfSquats,
        countOfFalls: session.countOfFalls,
      };
    });
  };

  const totalSpentTime = results.totalTime.toFixed(0);

  return (
    <GameDetailLayout
      totalSpentTime={totalSpentTime}
      headTabs={headTabs}
      data={results.result}
      dataConverter={convertDataToDataTable}
    />
  );
}

export default DetailJumpFrog;
