import { usePatient } from "contexts/patient-context/PatientContext";
import { useTranslation } from "react-i18next";

import GameDetailLayout from "../GameDetailLayout";
import { StyledItem } from "components/TableSticky/TableSticky";
import {
  PuzzleDataTableRow,
  PuzzleSessionData,
} from "types/games/puzzlesGameTypes";

function DetailPuzzles() {
  const { t } = useTranslation();
  const { setSessionDetailSelected, results } = usePatient();

  const headTabs = [
    { key: "order", label: t("table.order") },
    { key: "spentTime", label: t("table.spentTime") },
    { key: "pictures", label: t("game.pictures") },
    { key: "view", label: t("table.viewDetails") },
  ];

  const columnStyles: StyledItem[] = [
    {
      key: "view",
      style: {
        textDecoration: "underline",
        color: "var(--text-color-blue)",
        cursor: "pointer",
      },
    },
  ];

  const convertDataToDataTable = (
    _data: PuzzleSessionData[]
  ): PuzzleDataTableRow[] | null => {
    if (_data.length === 0) return null;
    return _data.map((session, index) => {
      return {
        order: index + 1,
        spentTime: session.pictures
          .reduce((total, picture) => total + picture.spentTime, 0)
          .toFixed(2),
        pictures: session.pictures.length,
        view: (
          <div
            onClick={() => {
              setSessionDetailSelected(session);
            }}
          >
            {t("table.viewDetails")}
          </div>
        ),
      };
    });
  };

  const totalSpentTime = results.totalTime.toFixed(0);

  return (
    <GameDetailLayout
      totalSpentTime={totalSpentTime}
      headTabs={headTabs}
      columnStyles={columnStyles}
      data={results.result}
      dataConverter={convertDataToDataTable}
    />
  );
}

export default DetailPuzzles;
